<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <v-card class="shadow rounded-lg">
            <v-card-title>
              Paramètres
            </v-card-title>
            <v-divider/>
            <v-card-text class="px-8">

              <v-row>
                <v-col>
                  <p class="font-weight-medium fs-16">Performance</p>
                  <v-checkbox v-model="cache" hide-details label="Supprimer le cache de l'application"></v-checkbox>
                  <v-checkbox v-model="reset" label="Réinitialiser l'application"></v-checkbox>
                </v-col>
                <v-col>
                  <img :src="require('@/assets/cog.svg')"
                       alt="cog"
                       width="250">
                </v-col>
              </v-row>


            </v-card-text>
          </v-card>

          <div class="text-end mt-3">
            <v-btn color="primary"
                   :disabled="!cache && !reset"
                   @click="save"
                   depressed>
              Appliquer
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
    data(){
        return {
            cache : false,
            reset : false,
        }
    },
    methods : {
        save(){
            localStorage.clear()
            this.$router.go(0);
        }
    }
}
</script>

<style scoped>

</style>